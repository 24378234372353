<template>
  <section class="tab">
    <h1 class="title">
      {{ tempProduct.name }}
      <span class="package-type"> {{ tempProduct.package_type }} </span>
    </h1>

    <div
      v-if="tempProduct.package_schedules === 'Range de Data'"
      class="dates mb-10"
    >
      <div class="departure mb-10">
        <b><i class="fa-regular fa-calendar-days"></i> Datas de ida:</b>
        <font class="day" v-for="(date, i) in packageDepartureDates" :key="i"
          ><span v-if="i !== 0">,</span> {{ date }}
        </font>
      </div>
      <div class="return">
        <b><i class="fa-regular fa-calendar-days"></i> Datas de volta:</b>
        <font class="day" v-for="(date, i) in packageReturnDates" :key="i"
          ><span v-if="i !== 0">,</span> {{ date }}
        </font>
      </div>
    </div>

    <aRow class="mb-10" type="flex" justify="start" :gutter="40">
      <aCol v-if="tempProduct.package_schedules === 'Mínimo de noites/dias'">
        <b><i class="fa-regular fa-calendar-days"></i> Período:</b>

        <span>
          {{ parseInt(tempProduct.package_min_nights) + 1 }} dias /
          {{ tempProduct.package_min_nights }} noites
        </span>
      </aCol>
      <aCol class="weekdays">
        <b><i class="fa-regular fa-calendar-check"></i> Saídas:</b>
        <span v-if="tempProduct.package_departures === 'Dias da semana'">
          <font class="day" v-for="(day, i) in packageWeekdays" :key="i">
            <span v-if="i !== 0">,</span> {{ day }}
          </font>
        </span>
        <span v-else> Diárias </span>
      </aCol>
    </aRow>

    <div
      v-if="tempProduct.package_type === 'Pacote completo com Aéreo'"
      class="places mb-10"
    >
      <b> <i class="fa-solid fa-plane-departure"></i> Saindo de:</b>
      {{ tempProduct.package_airport }}
    </div>

    <div class="religion mb-10">
      <b> <i class="fa-solid fa-church"></i> Religião:</b>
      {{ tempProduct.package_religion }}
    </div>

    <div class="places mb-30">
      <b> <i class="fa-solid fa-earth-africa"></i> Visitando:</b>
      <font class="day" v-for="(destination, i) in packageDestinations" :key="i"
        ><span v-if="i !== 0">|</span> {{ destination }}
      </font>
    </div>

    <div
      class="desc"
      v-if="tempProduct.description"
      v-html="tempProduct.description"
    />

    <div v-if="packageIncludesRows.length" class="includes mb-30">
      <ul class="list">
        <li v-for="({ id }, i) in packageIncludesRows" :key="i" class="item">
          <i class="fa-solid fa-check-double"></i>
          {{ tempProduct[`package_includes_${id}`] }}
        </li>
      </ul>
    </div>

    <div class="obs" v-if="tempProduct.package_observations">
      <div class="mb-10"><b class="f12">Observações:</b></div>
      <div class="f12" v-html="tempProduct.package_observations"></div>
    </div>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ViewPackageDataTab",
  props: {
    tempProduct: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      packageIncludesRows: [],
      packageWeekdays: [],
      packageDestinations: [],
      packageDepartureDates: [],
      packageReturnDates: [],
    };
  },
  mounted() {
    this.packageIncludesRows = this.tempProduct.package_schedule_rows
      ? JSON.parse(this.tempProduct.package_schedule_rows)
      : [];
    this.packageWeekdays = this.tempProduct.package_weekdays
      ? JSON.parse(this.tempProduct.package_weekdays)
      : [];
    this.packageDestinations = this.tempProduct.package_destinations
      ? JSON.parse(this.tempProduct.package_destinations)
      : [];
    this.packageDepartureDates = this.tempProduct.package_departure_dates
      ? JSON.parse(this.tempProduct.package_departure_dates)
      : [];
    this.packageReturnDates = this.tempProduct.package_return_dates
      ? JSON.parse(this.tempProduct.package_return_dates)
      : [];

    setTimeout(() => {
      const newDepartureArray = this.packageDepartureDates.map((date) => {
        return this.formatMultiDates(date);
      });

      this.packageDepartureDates = newDepartureArray;

      const newReturnArray = this.packageReturnDates.map((date) => {
        return this.formatMultiDates(date);
      });

      this.packageReturnDates = newReturnArray;
    }, 100);
  },
};
</script>

<style lang="sass" scoped>
.tab
  color: #333
  .package-type
    font-size: 13px
    font-weight: 400
    border: 1px solid #ffc579
    padding: 1px 10px
    border-radius: 6px
    letter-spacing: 0
    position: relative
    top: -3px
    margin-left: 10px
  .weekdays
    .day
      //padding-right: 5px
  .list
    padding: 0
    margin: 0
    list-style: none
    .item
      padding: 0 0 8px 0
      margin: 0
      color: #333
      .fa-check-double
        color:  #00ccb2
  .title
    color: #e09042 !important
    font-size: 28px
    letter-spacing: -1px
    font-weight: 600
</style>
